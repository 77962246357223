export const TermsOfServicePage = () => {
  return (
    <div className='px-4 sm:px-8 md:px-12 lg:px-24 my-8 sm:my-12 lowercase max-w-[768px] mx-auto'>
      <h1 className='text-2xl font-bold'>Calmi Terms of Service</h1>
      <h2 className='text-xl font-medium mt-6 mb-1'>1. Introduction</h2>
      <p>
        Welcome to calmi (calmi.so), an AI-powered mental health application. By
        accessing or using our app, you agree to these Terms of Service
        ("Terms"). If you disagree with any part of these Terms, please do not
        use our services.
      </p>
      <h2 className='text-xl font-medium mt-6 mb-1'>
        2. Eligibility and Age Restrictions
      </h2>
      <p>
        You must be at least 18 years old to use calmi. If you are between 13
        and 18 years old, you may only use calmi with the consent and
        supervision of a parent or legal guardian. We do not knowingly collect
        or solicit personal information from individuals under 13.
      </p>
      <h2 className='text-xl font-medium mt-6 mb-1'>3. User Accounts</h2>
      <p>
        To create an account, you must provide a valid email address and may be
        required to provide additional personal information. You are responsible
        for maintaining the confidentiality of your account credentials and for
        all activities under your account.
      </p>
      <h2 className='text-xl font-medium mt-6 mb-1'>
        4. Subscriptions and Payment
      </h2>
      <p>
        calmi offers three free AI therapy sessions. Continued use requires a
        subscription. Payments are processed through Stripe, subject to Stripe's
        Terms of Service and Privacy Policy. Our refund policy allows for
        pro-rated refunds within the first 14 days of a new subscription.
      </p>
      <h2 className='text-xl font-medium mt-6 mb-1'>
        5. AI Therapy and Liability Disclaimer
      </h2>
      <p>
        calmi provides general mental health advice through AI technology.
        However:
      </p>
      <ul>
        <li>calmi is not a licensed therapist or mental health provider.</li>
        <li>
          The AI is not a substitute for professional medical advice, diagnosis,
          or treatment.
        </li>
        <li>
          calmi does not guarantee the accuracy, completeness, or
          appropriateness of the AI's advice.
        </li>
        <li>
          You acknowledge that calmi is not responsible for any consequences,
          harm, or losses resulting from using the AI's advice.
        </li>
        <li>
          calmi is not suitable for emergency situations or crisis intervention.
        </li>
      </ul>
      <p>
        If you are experiencing a mental health emergency, please contact your
        local emergency services immediately or use a crisis helpline.
      </p>
      <h2 className='text-xl font-medium mt-6 mb-1'>
        6. Privacy and Data Protection
      </h2>
      <p>We prioritize the protection of your sensitive information:</p>
      <ul>
        <li>All conversations are encrypted end-to-end.</li>
        <li>
          We do not share your personal data or conversation logs with third
          parties, except as required by law.
        </li>
        <li>
          Data is stored securely and in compliance with applicable data
          protection regulations.
        </li>
      </ul>
      <p>For full details, please refer to our Privacy Policy.</p>
      <h2 className='text-xl font-medium mt-6 mb-1'>7. Communication</h2>
      <p>
        By signing up, you consent to receive emails from calmi regarding
        service updates, feedback requests, and promotional content. You may opt
        out of promotional emails by following the instructions in the email.
      </p>
      <h2 className='text-xl font-medium mt-6 mb-1'>8. Data Deletion</h2>
      <p>
        You may request the deletion of your account and associated data at any
        time by contacting our support team. We will comply with your request
        within 30 days, subject to legal retention requirements.
      </p>
      <h2 className='text-xl font-medium mt-6 mb-1'>
        9. Intellectual Property
      </h2>
      <p>
        calmi retains all rights to the app's content, features, and
        functionality. Users retain ownership of any original content they input
        during interactions, granting calmi a non-exclusive license to use this
        content for service improvement.
      </p>
      <h2 className='text-xl font-medium mt-6 mb-1'>10. Prohibited Uses</h2>
      <p>You agree not to:</p>
      <ul>
        <li>Use the app for any illegal purpose</li>
        <li>Attempt to gain unauthorized access to any part of the app</li>
        <li>Use the app to harass, abuse, or harm others</li>
        <li>Impersonate any person or entity</li>
        <li>Interfere with or disrupt the app's functionality</li>
      </ul>
      <h2 className='text-xl font-medium mt-6 mb-1'>11. Termination</h2>
      <p>
        calmi reserves the right to suspend or terminate your account for
        violations of these Terms or for any other reason at our discretion.
      </p>
      <h2 className='text-xl font-medium mt-6 mb-1'>
        12. Modifications to Terms
      </h2>
      <p>
        We may modify these Terms at any time. Continued use of the app after
        changes constitutes acceptance of the new terms. We will notify users of
        significant changes via email.
      </p>
      <h2 className='text-xl font-medium mt-6 mb-1'>13. Accessibility</h2>
      <p>
        calmi strives to make its services accessible to all users. If you have
        specific accessibility needs, please contact our support team.
      </p>
      <h2 className='text-xl font-medium mt-6 mb-1'>14. Third-party Links</h2>
      <p>
        calmi may provide links to external resources. We are not responsible
        for the content or practices of these third-party sites.
      </p>
      <h2 className='text-xl font-medium mt-6 mb-1'>15. Limitations of AI</h2>
      <p>
        While our AI strives to provide helpful advice, it has limitations in
        understanding complex human emotions and situations. It should not be
        considered a replacement for human judgment or professional mental
        health care.
      </p>
      <h2 className='text-xl font-medium mt-6 mb-1'>16. Contact Information</h2>
      <p>
        If you have any questions about these Terms, please contact us at:
        <br />
        Dennis Aydin <br />
        <a href='mailto:dennis@calmi.so'>dennis@calmi.so</a>
      </p>
      <p className='mt-6'>
        By using calmi, you acknowledge that you have read, understood, and
        agree to be bound by these Terms of Service.
      </p>
      <p className='mt-4'>Last updated: 09.14.2024</p>
    </div>
  );
};
