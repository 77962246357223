import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { Loader2, Sparkles } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ConfirmDialog } from '../components';
import { toast } from '../hooks';

import { isPast } from 'date-fns';
import { supabase } from '../supabaseClient';
import { UserWithDetails } from '../types';

export const AccountPage = () => {
  const [user, setUser] = useState<UserWithDetails | null>(null);
  const [isUserLoading, setIsUserLoading] = useState(false);

  const subscriptionEndDate = user?.subscription_end_date
    ? new Date(user.subscription_end_date).toLocaleDateString('us-EN', {
        dateStyle: 'medium',
      })
    : null;

  const isSubscriptionExpired = subscriptionEndDate
    ? isPast(subscriptionEndDate)
    : null;

  const setUserFromDb = async () => {
    setIsUserLoading(true);
    const user = await supabase.auth.getUser();

    const { data: userDetails } = await supabase
      .from('users')
      .select('*')
      .eq('user_id', user.data.user?.id)
      .single();

    const userWithDetails = { ...user.data.user, ...userDetails };

    setUser(userWithDetails);
    setIsUserLoading(false);
  };

  const { mutate: handleCancelSubscription, isPending } = useMutation({
    mutationFn: () => {
      const userId = user?.user_id;

      return axios.post(
        `${process.env.REACT_APP_API_URL}/cancel-subscription`,
        {
          userId,
        }
      );
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        return toast({
          title: 'error!',
          description: `an error occurred while canceling subscription, try again or contact support.
            `,
          variant: 'destructive',
        });
      }
    },
    onSuccess: () => {
      return toast({
        title: 'success! subscription canceled',
      });
    },
  });

  useEffect(() => {
    setUserFromDb();
  }, [isPending]);

  return (
    <div className='flex items-center justify-center min-h-screen px-4 sm:px-8 md:px-12 lg:px-24'>
      <div className='w-full max-w-[600px] min-h-[160px] border rounded-md border-neutral-800 p-4'>
        <h1 className='text-2xl font-bold'>account</h1>
        {isUserLoading ? (
          <div className='w-full h-full grid justify-center items-center'>
            <Loader2 className='h-8 w-8 animate-spin' />
          </div>
        ) : (
          <>
            <p className='mt-2'>email: {user?.email}</p>

            {!user?.is_subscribed &&
              subscriptionEndDate &&
              !isSubscriptionExpired && (
                <div className='mt-6 grid gap-2'>
                  <p className=' font-bold text-sm'>
                    your subscription has been canceled and is active until:{' '}
                    {subscriptionEndDate}
                  </p>
                </div>
              )}

            {user?.is_subscribed ? (
              <ConfirmDialog
                handleConfirm={handleCancelSubscription}
                triggerButtonText='cancel subscription'
                headline='want to cancel for real?'
                description="no worries, we won't pull the plug right away. you can keep using calmi until your current month runs out."
                loading={isPending}
              />
            ) : (
              <div className='mt-6 grid gap-4 max-w-52'>
                <div className='flex items-center justify-between'>
                  <p className='font-bold'>plan</p>
                  <p className='px-1 bg-[#FFC700] rounded-sm font-bold'>free</p>
                </div>

                <Link
                  to='/pricing'
                  className='flex justify-center items-center h-8 gap-1 px-2 py-1 sm:px-3 sm:py-1 rounded-md text-[#1E1E1E] font-bold transition duration-300 ease-in-out font-mono transform hover:scale-105 bg-[#FFC700] hover:bg-[#FFB900]'
                >
                  <Sparkles className='w-3 h-3 mb-1' />
                  <span>get plus</span>
                </Link>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
