import { Quote } from 'lucide-react';
import { useEffect, useState } from 'react';

export const Testimonials = () => {
  const [isAnimated, setIsAnimated] = useState(false);

  const data = [
    {
      name: 'pierce',
      comment:
        "hey! i just tried your ai model and it's sick, i already feel a little better",
    },
    {
      name: 'decim0330',
      comment: 'This would actually work on me better than my therapist',
    },
    {
      name: 'snowphobic',
      comment:
        'this is honestly such a good site and i appreciate the time and dedication you put into making it! i hope this blows up',
    },
    {
      name: 'mvggotz',
      comment:
        "i'm... speechless. i used this for less than 5 min and i'm already crying... . i really needed this. thank you. so much",
    },
    {
      name: 'gilly.gerel',
      comment: 'damn just had a therapy session',
    },
    {
      name: 'orianagxmez',
      comment:
        'I tried it and I can genuinely say - this is insane, I am so amused by how good the AI is',
    },
    {
      name: 'aflynaaa',
      comment: 'THIS ATEEEEE🔥',
    },
    {
      name: 'eggs eggs eggs tentacion',
      comment: 'this literally is the biggest discovery in my life',
    },
    {
      name: 'hope_fm9',
      comment:
        'The best thing ever! I love it im not joking. Thank you very much for this!! 💜',
    },
    {
      name: 'taehoonsgf',
      comment:
        'honestly this is really innovative and smart!! A lot of people can’t afford therapists and just need someone to vent to, it’s an amazing alternative 🙂‍↕️hope this blows up legend!!',
    },
  ];

  const addAnimation = () => {
    setIsAnimated(true);
  };

  useEffect(() => {
    if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      addAnimation();
    }
  }, []);

  const duplicatedList = [...(data ?? []), ...(data ?? [])];

  return (
    <article className='flex flex-col gap-4 overflow-hidden'>
      <ul
        className='testimonials'
        data-animated={isAnimated}
        data-direction='left'
        data-duration='slow'
      >
        <div className='inner-scroller'>
          {duplicatedList &&
            duplicatedList.map((testimonial, index) => (
              <div
                key={testimonial.name + index}
                className='grid gap-1 p-4 max-w-96 border border-zinc-200 rounded-md'
              >
                <div className='flex items-center gap-1'>
                  <p className='flex justify-center items-center w-8 h-8  rounded-full'>
                    <Quote className='w-4 h-4' />
                  </p>
                  <p className='text-gray-700 mb-[2px]'>{testimonial.name}</p>
                </div>

                <p className='font-medium'>{testimonial.comment}</p>
              </div>
            ))}
        </div>
      </ul>

      <ul
        className='testimonials'
        data-animated={isAnimated}
        data-direction='right'
        data-duration='medium'
      >
        <div className='inner-scroller'>
          {duplicatedList &&
            duplicatedList.map((testimonial, index) => (
              <div
                key={testimonial.name + index}
                className='grid gap-1 p-4 max-w-96 border border-zinc-200 rounded-md'
              >
                <div className='flex items-center gap-1'>
                  <p className='flex justify-center items-center w-8 h-8  rounded-full'>
                    <Quote className='w-4 h-4' />
                  </p>
                  <p className='text-gray-700 mb-[2px]'>{testimonial.name}</p>
                </div>

                <p className='font-medium'>{testimonial.comment}</p>
              </div>
            ))}
        </div>
      </ul>
    </article>
  );
};
