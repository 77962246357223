import { Loader2 } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Sidebar } from './components/sidebar';
import { useUserData } from './hooks';
import { useSession } from './hooks/use-session';
import { supabase } from './supabaseClient';

const Layout = () => {
  const { session, setSession } = useSession();
  const [sessionCountLimitReached, setSessionCountLimitReached] =
    useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [sessionCount, setSessionCount] = useState(0);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user, loadingUser, refreshUser } = useUserData();

  const checkUserSubscription = useCallback(() => {
    if (!user) {
      setIsSubscribed(false);
      setSessionCountLimitReached(false);
      setSessionCount(0);
      return;
    }
    setIsSubscribed(user.is_subscribed);
    setSessionCount(user.session_count);
    setSessionCountLimitReached(!user.is_subscribed && user.session_count >= 3);
  }, [user]);

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error signing out:', error);
      return;
    }
    setSession(null);
    navigate('/');
  };

  useEffect(() => {
    checkUserSubscription();
  }, [user, checkUserSubscription]);

  return (
    <>
      {loadingUser && !user ? (
        <div className='absolute inset-0 flex justify-center items-center gap-2 bg-white'>
          <Loader2 className='h-4 w-4 animate-spin' />
          <p className='text-sm'>setting up calmi...</p>
        </div>
      ) : (
        <Sidebar
          session={session}
          isSubscribed={isSubscribed}
          handleLogout={handleLogout}
          currentPathname={pathname}
          sessionCountLimitReached={sessionCountLimitReached}
          sessionCount={sessionCount}
        >
          <Outlet context={{ user, refreshUser }} />
        </Sidebar>
      )}
    </>
  );
};

export default Layout;
