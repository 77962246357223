import { isPast } from 'date-fns';
import { Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import './App.css';
import Footer from './components/footer';
import Home from './components/home';
import Session from './components/session';
import './globals.css';
import { useSession } from './hooks/use-session';
import { supabase } from './supabaseClient';
import { UserWithDetails } from './types';

type ContextType = {
  user: UserWithDetails | null;
  loadingUser: boolean;
  refreshUser: () => void;
};

function App() {
  const { session } = useSession();
  const [sessionCountLimitReached, setSessionCountLimitReached] =
    useState(false);
  const { user, loadingUser, refreshUser } = useOutletContext<ContextType>();

  const checkUserSubscription = async () => {
    if (!user) return;

    const subscriptionEndDate = new Date(user.subscription_end_date);
    const isSubscriptionExpired = isPast(subscriptionEndDate);

    const isNotSubscribed = !user.is_subscribed && isSubscriptionExpired;
    const hasReachedFreeLimit = user.session_count >= 3;

    setSessionCountLimitReached(isNotSubscribed && hasReachedFreeLimit);
  };

  const handleSessionCount = async () => {
    if (!user) return;

    const updatedSessionCount = user.session_count + 1;

    const { error: updateError } = await supabase
      .from('users')
      .update({
        session_count: updatedSessionCount,
      })
      .eq('user_id', user.user_id);

    if (updateError) return;

    refreshUser();

    // setSessionCountLimitReached(
    //   !user.is_subscribed && updatedSessionCount >= 3
    // );
  };

  useEffect(() => {
    checkUserSubscription();
  }, [user]);

  return (
    <>
      {loadingUser && !user ? (
        <div className='absolute inset-0 flex justify-center items-center gap-2 bg-white'>
          <Loader2 className='h-4 w-4 animate-spin' />
          <p className='text-sm'>setting up calmi...</p>
        </div>
      ) : session && user ? (
        <Session
          userId={user.user_id}
          sessionCountLimitReached={sessionCountLimitReached}
          handleSessionCount={handleSessionCount}
        />
      ) : (
        <>
          <Home />
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
