export const PLAN =
  process.env.NODE_ENV === 'production'
    ? {
        link: 'https://buy.stripe.com/00g16Pgdt9SN5UscMM',
        priceId: 'price_1PzL0fAQBZWZL0qubvBAXmpn',
        price: 14,
      }
    : {
        link: 'https://buy.stripe.com/test_4gwbJoaYB4gT9j2eUU',
        priceId: 'price_1PvmVCAQBZWZL0quNxp4hUtd',
        price: 14,
      };
