function Footer() {
  return (
    <div>
      <footer className='flex flex-col sm:flex-row items-center sm:items-start justify-between py-4 sm:py-6 md:py-8 px-4 sm:px-8 md:px-12 space-y-4 sm:space-y-0'>
        <p className='text-xs sm:text-sm text-gray-600 order-3 sm:order-1 mt-2 sm:mt-2'>
          © 2024 calmi by dennis han
        </p>
        <div className='flex-grow flex justify-center order-1 sm:order-2 mb-4 sm:mb-0'>
          <a href='https://elevenlabs.io/text-to-speech'>
            <img
              src='https://storage.googleapis.com/eleven-public-cdn/images/elevenlabs-grants-logo.png'
              alt='Text to Speech'
              className='h-4 sm:h-5 md:h-6 w-auto'
            />
          </a>
        </div>
        <div className='flex space-x-4 sm:space-x-6 md:space-x-9 order-2 sm:order-3'>
          <a
            href='https://www.instagram.com/adennishan/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              className='h-6 w-6 sm:h-7 sm:w-7 md:h-8 md:w-8'
              src='./images/instagram.png'
              alt='Instagram'
            />
          </a>
          <a
            href='https://x.com/aDennisHan'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              className='h-6 w-6 sm:h-7 sm:w-7 md:h-9 md:w-9'
              src='./images/twitter.png'
              alt='Twitter'
            />
          </a>
          <a
            href='https://www.linkedin.com/in/dennishanaydin/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              className='h-6 w-6 sm:h-7 sm:w-7 md:h-8 md:w-8'
              src='./images/linkedin.png'
              alt='LinkedIn'
            />
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
