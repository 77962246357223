import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from './ui/alert-dialog';
import { Button } from './ui/button';

interface ConfirmDialogProps {
  triggerButtonText: string;
  description?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  headline?: string;
  loading?: boolean;
  handleConfirm: () => void;
}

export const ConfirmDialog = ({
  triggerButtonText,
  confirmButtonText = 'confirm',
  cancelButtonText = 'cancel',
  headline,
  description,
  loading,
  handleConfirm,
}: ConfirmDialogProps) => {
  const [open, setOpen] = useState(false);

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button className='mt-4 w-auto px-6 rounded-md border-none bg-[#FFC700] hover:bg-[#FFB900] text-[#1E1E1E] font-bold font-mono outline-none'>
          {triggerButtonText}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          {headline && <AlertDialogTitle>{headline}</AlertDialogTitle>}

          {description && (
            <AlertDialogDescription>{description}</AlertDialogDescription>
          )}
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>{cancelButtonText}</AlertDialogCancel>
          {/* TODO(VN): Add loading */}
          <Button
            onClick={() => {
              handleConfirm();
              setOpen(false);
            }}
            className='bg-[#FFC700] hover:bg-[#FFB900] text-primary'
          >
            {loading ? (
              <Loader2 className='h-4 w-4 animate-spin' />
            ) : (
              <span> {confirmButtonText}</span>
            )}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
