import { Session } from '@supabase/supabase-js';
import {
  ArrowRightFromLine,
  Home,
  Info,
  Menu,
  Sparkles,
  User,
} from 'lucide-react';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { cn } from '../utils';
import { Button } from './ui/button';
import { Progress } from './ui/progress';
import { Sheet, SheetContent, SheetTrigger } from './ui/sheet';

interface SidebarProps {
  children: ReactNode;
  currentPathname: string;
  session: Session | null;
  isSubscribed: boolean;
  sessionCountLimitReached: boolean;
  sessionCount: number;
  handleLogout: () => void;
}

export function Sidebar({
  currentPathname,
  children,
  session,
  isSubscribed,
  sessionCountLimitReached,
  sessionCount,
  handleLogout,
}: SidebarProps) {
  const isActiveRoute = (route: string) => currentPathname === route;

  if (!session) {
    return <div className='w-full'>{children}</div>;
  }

  return (
    <div className='flex h-screen w-full'>
      <div className='hidden md:flex flex-col w-[220px] lg:w-[280px] border-r bg-muted/40 fixed h-full'>
        <div className='flex h-14 items-center px-4 lg:h-[60px] lg:px-6'>
          <Link to='/' className='flex items-center gap-2 font-semibold'>
            <span className='mb-1 text-2xl font-black font-sans'>calmi</span>
          </Link>
        </div>
        <div className='flex flex-col flex-1 overflow-y-auto'>
          <nav className='flex flex-col flex-1 justify-between px-2 text-sm font-medium lg:px-4 py-4'>
            <div className='grid w-full'>
              <Link
                to='/'
                className={cn(
                  'flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary hover:bg-zinc-100 active:bg-zinc-200 active:text-primary focus-visible:bg-zinc-200 focus-visible:text-primary outline-none',
                  isActiveRoute('/') && 'text-primary bg-zinc-100'
                )}
              >
                <Home className='h-4 w-4' />
                home
              </Link>
              <Link
                to='/account'
                className={cn(
                  'flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary hover:bg-zinc-100 active:bg-zinc-200 active:text-primary focus-visible:bg-zinc-200 focus-visible:text-primary outline-none',
                  isActiveRoute('/account') && 'text-primary bg-zinc-100'
                )}
              >
                <User className='h-4 w-4' />
                account
              </Link>
            </div>

            <button
              onClick={handleLogout}
              className='flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary hover:bg-zinc-100 active:bg-zinc-200 active:text-primary focus-visible:bg-zinc-200 focus-visible:text-primary outline-none'
            >
              <ArrowRightFromLine className='h-4 w-4' />
              log out
            </button>
          </nav>
        </div>

        {!isSubscribed && (
          <div className='p-4 grid gap-4 border-t'>
            <div className='flex items-center justify-between'>
              <p className='font-bold'>plan</p>
              <p className='px-1 bg-[#FFC700] rounded-sm font-bold'>free</p>
            </div>
            <div className='grid gap-1'>
              <div className='flex items-center justify-between font-mono text-xs'>
                <p>free sessions</p>
                <p>{sessionCount}/3</p>
              </div>
              <Progress value={(sessionCount / 3) * 100} />

              {sessionCountLimitReached && (
                <div className='grid grid-cols-[auto,1fr] items-center gap-2 border rounded-sm py-1 px-2 mt-3'>
                  <Info className='w-4 h-4' />
                  <p className='text-sm font-semibold'>
                    free limit reached. upgrade to continue.
                  </p>
                </div>
              )}
            </div>
            <Link
              to='/pricing'
              className='flex justify-center items-center h-8 gap-1 px-2 py-1 sm:px-3 sm:py-1 rounded-md text-[#1E1E1E] font-bold transition duration-300 ease-in-out font-mono transform hover:scale-105 bg-[#FFC700] hover:bg-[#FFB900]'
            >
              <Sparkles className='w-3 h-3 mb-1' />
              <span>get plus</span>
            </Link>
          </div>
        )}
      </div>

      <div className='relative flex-1 flex flex-col md:ml-[220px] lg:ml-[280px]'>
        <header className='flex md:hidden h-14 items-center gap-4 border-b bg-muted/40 px-4 lg:h-[60px] lg:px-6 sticky top-0 z-10'>
          <Sheet>
            <SheetTrigger asChild>
              <Button
                variant='outline'
                size='icon'
                className='shrink-0 md:hidden'
              >
                <Menu className='h-5 w-5' />
                <span className='sr-only'>Toggle navigation menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent side='left' className='flex flex-col'>
              <nav className='flex flex-col justify-between gap-2 flex-1 text-lg font-medium pt-8'>
                <div>
                  <Link
                    to='/'
                    className={cn(
                      'mx-[-0.65rem] flex items-center gap-4 rounded-xl px-3 py-2 text-muted-foreground hover:text-foreground',
                      isActiveRoute('/') && 'bg-zinc-100 text-primary'
                    )}
                  >
                    <Home className='h-5 w-5' />
                    home
                  </Link>
                  <Link
                    to='/account'
                    className={cn(
                      'mx-[-0.65rem] flex items-center gap-4 rounded-xl px-3 py-2 text-muted-foreground hover:text-foreground',
                      isActiveRoute('/account') && 'bg-zinc-100 text-primary'
                    )}
                  >
                    <User className='h-5 w-5' />
                    account
                  </Link>
                </div>

                <button
                  onClick={handleLogout}
                  className='mx-[-0.65rem] flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary hover:bg-zinc-100 active:bg-zinc-200 active:text-primary focus-visible:bg-zinc-200 focus-visible:text-primary outline-none'
                >
                  <ArrowRightFromLine className='h-4 w-4' />
                  log out
                </button>
              </nav>

              {!isSubscribed && (
                <div className='mt-auto px-6 py-4 grid gap-4 border-t -mx-6'>
                  <div className='flex items-center justify-between'>
                    <p className='font-bold'>plan</p>
                    <p className='px-1 bg-[#FFC700] rounded-sm font-bold'>
                      free
                    </p>
                  </div>

                  <div className='grid gap-1'>
                    <div className='flex items-center justify-between font-mono text-xs'>
                      <p>free sessions</p>
                      <p>{sessionCount}/3</p>
                    </div>
                    <Progress value={(sessionCount / 3) * 100} />

                    {sessionCountLimitReached && (
                      <div className='grid grid-cols-[auto,1fr] items-center gap-2 border rounded-sm py-1 px-2 mt-3'>
                        <Info className='w-4 h-4' />
                        <p className='text-sm font-semibold'>
                          free limit reached. upgrade to continue.
                        </p>
                      </div>
                    )}
                  </div>

                  <Link
                    to='/pricing'
                    className='flex justify-center items-center h-8 gap-1 px-2 py-1 sm:px-3 sm:py-1 rounded-md text-[#1E1E1E] font-bold transition duration-300 ease-in-out font-mono transform hover:scale-105 bg-[#FFC700] hover:bg-[#FFB900]'
                  >
                    <Sparkles className='w-3 h-3 mb-1' />
                    <span>get plus</span>
                  </Link>
                </div>
              )}
            </SheetContent>
          </Sheet>
        </header>
        <main className='flex-1 overflow-y-auto p-4 pb-8 lg:p-6'>
          {children}
        </main>
      </div>
    </div>
  );
}
