import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Analytics } from '@vercel/analytics/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import { Toaster } from './components';
import './index.css';
import Layout from './layout';
import { AccountPage } from './pages/account-page';
import { AiDisclaimerPage } from './pages/ai-disclaimer-page';
import { PricingPage } from './pages/pricing-page';
import { PrivacyPolicyPage } from './pages/privacy-policy-page';
import { TermsOfServicePage } from './pages/terms-of-service-page';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <App />,
      },
      {
        path: 'pricing',
        element: <PricingPage />,
      },
      {
        path: 'account',
        element: <AccountPage />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicyPage />,
      },
      {
        path: 'terms-of-service',
        element: <TermsOfServicePage />,
      },
      {
        path: 'ai-disclaimer',
        element: <AiDisclaimerPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <Toaster />
      <Analytics />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
