import { useEffect, useRef, useState } from 'react';
import { cn } from '../utils/cn.util';

const AudioVisualizer = ({ playback, onClick, isDisabled }) => {
  const canvasRef = useRef(null);
  const animationRef = useRef(null);
  const timeRef = useRef(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const animate = (time) => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Base radius (increased from 40 to 80)
      let radius = 100;

      if (playback && playback.analyser) {
        const bufferLength = playback.analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);
        playback.analyser.getByteFrequencyData(dataArray);

        const average = dataArray.reduce((a, b) => a + b) / bufferLength;
        // Amplify the effect (increased from 20 to 50)
        radius += (average / 256) * 30;
      }

      // Add a subtle continuous pulsation
      const pulsation = Math.sin(timeRef.current * 0.003) * (isHovered ? 2 : 1);
      radius += pulsation;

      // Apply ease-out effect when hovered
      if (isHovered) {
        radius += 5 * Math.pow(0.98, time - timeRef.current);
      }

      ctx.beginPath();
      ctx.arc(canvas.width / 2, canvas.height / 2, radius, 0, 2 * Math.PI);
      ctx.fillStyle = '#FFC700';
      ctx.fill();

      timeRef.current = time;
      animationRef.current = requestAnimationFrame(animate);
    };

    animate(0);

    return () => cancelAnimationFrame(animationRef.current);
  }, [playback, isHovered]);

  return (
    <div
      className={cn(
        'audio-visualizer-container cursor-pointer',
        isDisabled && 'pointer-events-none'
      )}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <canvas
        ref={canvasRef}
        width={300}
        height={300}
        style={{ maxWidth: '100%', height: 'auto' }}
        onClick={onClick}
      />
    </div>
  );
};

export default AudioVisualizer;
