import { useEffect, useRef, useState } from 'react';
import {
  AUDIO_CONTEXT_SETTINGS,
  CLEAR_BUFFER_TOKEN,
  INTERRUPT_TOKEN,
  SERVER_WS_URL,
  START_LISTENING_TOKEN,
} from '../constants';
import { useLogs } from '../hooks/use-logs';
import { supabase } from '../supabaseClient';
import { isMobile, Playback, Streamer } from '../utils';
import AudioVisualizer from './AudioVisualizer';
import ToggleSwitch from './ToggleSwitch';

export default function Session({
  handleSessionCount,
  sessionCountLimitReached,
  userId,
}: {
  handleSessionCount: () => void;
  sessionCountLimitReached: boolean;
  userId: string | null;
}) {
  const ws = useRef<WebSocket | null>(null);
  const streamer = useRef<Streamer | null>(null);
  const playback = useRef<Playback | null>(null);
  const lastEOS = useRef<Date | null>(null);
  const beginSessionButtonRef = useRef<HTMLButtonElement>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isGenZMode, setIsGenZMode] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState<
    'connecting...' | 'processing...' | '' | null
  >();
  const [logMessage, Logs, clearLogs] = useLogs();

  const isMobileDevice = isMobile();

  const handleGenZToggle = (isOn: boolean) => {
    setIsGenZMode(isOn);
  };

  const handleSpeakButtonToggle = () => {
    if (!isRecording) return;
    if (isSpeaking) {
      streamer.current?.endSpeech();
    } else {
      streamer.current?.startSpeech();
    }
    setIsSpeaking(!isSpeaking);
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && isRecording) {
        handleSpeakButtonToggle();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isRecording, isSpeaking]);

  const stopRecording = (graceful: boolean = false) => {
    handleSessionCount();
    setIsRecording(false);
    setIsSpeaking(false);
    streamer.current?.stop(graceful);
    playback.current?.stop(graceful);
    setConnectionStatus(null);
    ws.current?.close();
    ws.current = null;
    lastEOS.current = null;
  };

  const startRecording = async () => {
    setIsRecording(true);

    let initialMessageCount = 0;

    if (beginSessionButtonRef.current) {
      beginSessionButtonRef.current.blur();
    }

    if (!ws.current || ws.current.readyState !== WebSocket.OPEN) {
      ws.current = new WebSocket(`${SERVER_WS_URL}?isGenZ=${isGenZMode}`);

      if (initialMessageCount === 0) {
        setConnectionStatus('connecting...');
      }

      ws.current.binaryType = 'arraybuffer';
      ws.current.onopen = () => {
        setConnectionStatus('connecting...');

        ws.current &&
          (ws.current.onmessage = async (event) => {
            if (event.data instanceof ArrayBuffer) {
              playback.current?.addSamples(new Float32Array(event.data));

              if (initialMessageCount > 1) {
                setConnectionStatus('');
              }
            } else if (event.data === CLEAR_BUFFER_TOKEN) {
              playback.current?.clear().then((didInterrupt: boolean) => {
                if (didInterrupt) {
                  ws.current && ws.current.send(INTERRUPT_TOKEN);
                }
              });
            } else if (event.data === START_LISTENING_TOKEN) {
              playback.current?.once('playbackEnd', () => {
                logMessage('Press Enter to start/stop speaking or interrupt.');
                setConnectionStatus(null);
              });
            } else {
              logMessage(event.data);

              if (initialMessageCount < 1) {
                setConnectionStatus('connecting...');
              } else {
                // 2 sec. timeout to have a smoother transition from 'processing...' to actual voice response
                setTimeout(() => {
                  setConnectionStatus('');
                }, 2000);
              }

              if (!userId) return;

              if (initialMessageCount > 1) {
                const eventMessage = event.data as string;
                const role = eventMessage.startsWith('assistant')
                  ? 'ai'
                  : 'user';
                const message = eventMessage.replace(
                  /(assistant: |user: )/,
                  ''
                );

                const messageData = {
                  user_id: userId,
                  role,
                  message,
                };

                await supabase.from('message_history').insert(messageData);
              }

              initialMessageCount += 1;
            }
          });

        playback.current = new Playback(
          new AudioContext(AUDIO_CONTEXT_SETTINGS)
        );
        playback.current.on('playbackStart', () => {
          if (!lastEOS.current) {
            return;
          }
          const responseTime = new Date().getTime() - lastEOS.current.getTime();
          // logMessage("--- time.TOTAL_RESPONSE ", responseTime, " ms");
        });
        playback.current.on('playbackEnd', () => {
          if (initialMessageCount >= 1) {
            setConnectionStatus(null);
          }
        });

        playback.current.start();
        streamer.current = new Streamer(ws.current!, logMessage);
        streamer.current.on('speechStart', () => {
          playback.current?.clear().then((didInterrupt: boolean) => {
            if (didInterrupt) {
              // logMessage('interrupt recorded', didInterrupt);
              ws.current && ws.current.send(INTERRUPT_TOKEN);
            }
          });
        });
        streamer.current.on('speechEnd', () => {
          lastEOS.current = new Date();

          setConnectionStatus('processing...');
        });
        streamer.current.start();

        ws.current &&
          (ws.current.onclose = () => {
            logMessage('session ended');
            stopRecording(true);
            setConnectionStatus(null);
          });
      };

      if (initialMessageCount > 0) {
        setConnectionStatus(null);
      }

      ws.current.onerror = (event) => {
        logMessage(`websocket error: ${event}`);
      };
    }
  };

  return (
    <div className='flex flex-col bg-white'>
      {/* Mobile speak button */}
      {isRecording && (
        <div className='absolute bottom-8 left-0 right-0 flex justify-center z-20'>
          <button
            onClick={handleSpeakButtonToggle}
            className='px-6 py-3 rounded-full text-[#1E1E1E] font-bold text-lg transition duration-300 ease-in-out font-mono transform hover:scale-105 bg-[#FFC700] hover:bg-[#FFB900] shadow-lg'
          >
            {isSpeaking ? 'stop speaking' : 'start speaking'}
          </button>
        </div>
      )}
      {/* Top navigation section */}

      <div className='w-full max-w-full mx-auto sm:px-6 lg:px-8 py-2 sm:py-4'>
        <div className='flex items-center justify-end scale-80 sm:scale-100'>
          <ToggleSwitch initialState={isGenZMode} onToggle={handleGenZToggle} />
        </div>
      </div>

      {/* Main content */}
      <div className='grid items-center'>
        <div className='session-focus relative z-0'>
          <div className='w-full max-w-3xl flex justify-center mx-auto'>
            <AudioVisualizer
              isDisabled={sessionCountLimitReached}
              playback={playback.current}
              onClick={
                isRecording ? () => stopRecording(false) : startRecording
              }
            />
          </div>

          <h2 className='text-m font-bold text-center font-mono'>
            {sessionCountLimitReached ? (
              <span>free limit reached. upgrade to continue.</span>
            ) : connectionStatus || connectionStatus === '' ? (
              <span className='font-mono'>{connectionStatus}</span>
            ) : (
              <span>
                {!isRecording &&
                  !isMobileDevice &&
                  '<click> the circle to begin/end'}
                {!isRecording &&
                  isMobileDevice &&
                  '<tap> the circle to begin/end'}
                {isRecording &&
                  !isSpeaking &&
                  !isMobileDevice &&
                  'press <enter> to start speaking'}
                {isRecording &&
                  !isSpeaking &&
                  isMobileDevice &&
                  '<tap> to start speaking'}
                {isRecording &&
                  isSpeaking &&
                  !isMobileDevice &&
                  'listening... press <enter> to stop speaking'}
                {isRecording &&
                  isSpeaking &&
                  isMobileDevice &&
                  'listening... <tap> to stop speaking'}
                {isRecording &&
                  !isSpeaking &&
                  playback.current?.samples &&
                  playback.current.samples.length < 0 &&
                  'Processing...'}
                {isRecording &&
                  !isSpeaking &&
                  playback.current?.samples &&
                  playback.current.samples.length === 0 &&
                  playback.current.lastFramePlayed === 'non-silence' &&
                  'Speaking...'}
              </span>
            )}
          </h2>
        </div>
      </div>

      <div className='w-full flex justify-center mt-12'>
        <Logs />
      </div>
    </div>
  );
}
