export const AiDisclaimerPage = () => {
  return (
    <div className='px-4 sm:px-8 md:px-12 lg:px-24 my-8 sm:my-12 lowercase max-w-[768px] mx-auto'>
      <h1 className='text-2xl font-bold'>calmi AI Disclaimer</h1>
      <p>
        <strong>Last Updated:</strong> 09.14.2024
      </p>

      <p>
        Welcome to calmi, an AI-powered mental health application. Before using
        our services, please read this AI disclaimer carefully.
      </p>

      <h2 className='text-xl font-medium mt-6 mb-1'>1. Nature of Service</h2>
      <p>
        calmi provides conversational therapy through artificial intelligence
        (AI) technology. Our AI therapist is a computer program designed to
        engage in supportive conversations and provide general mental health
        information.
      </p>

      <h2 className='text-xl font-medium mt-6 mb-1'>
        2. Not a Substitute for Professional Care
      </h2>
      <ul>
        <li>
          calmi's AI therapy is not a substitute for professional mental health
          care, medical advice, diagnosis, or treatment.
        </li>
        <li>
          Our AI therapist is not a licensed mental health professional and
          cannot provide clinical diagnoses or treatment plans.
        </li>
        <li>
          We strongly encourage users to seek help from qualified mental health
          professionals for specific mental health concerns.
        </li>
      </ul>

      <h2 className='text-xl font-medium mt-6 mb-1'>3. Limitations of AI</h2>
      <ul>
        <li>
          While our AI is designed to be supportive, it has limitations in
          understanding complex human emotions, contexts, and situations.
        </li>
        <li>
          The AI may not always provide appropriate or accurate responses to all
          situations.
        </li>
        <li>The effectiveness of AI therapy may vary from person to person.</li>
      </ul>

      <h2 className='text-xl font-medium mt-6 mb-1'>4. Emergency Situations</h2>
      <ul>
        <li>
          calmi is not equipped to handle emergency situations or mental health
          crises.
        </li>
        <li>
          If you are experiencing a mental health emergency, please contact your
          local emergency services immediately or use a crisis helpline.
        </li>
        <li>
          For U.S. users, you can contact the National Suicide Prevention
          Lifeline at 1-800-273-8255 or text HOME to 741741 to reach a crisis
          counselor.
        </li>
      </ul>

      <h2 className='text-xl font-medium mt-6 mb-1'>
        5. Privacy and Data Usage
      </h2>
      <ul>
        <li>
          Your conversations with the AI are encrypted and stored securely.
        </li>
        <li>
          We may use anonymized conversation data to improve our AI system and
          services.
        </li>
        <li>
          Please refer to our Privacy Policy for more detailed information on
          data handling and protection.
        </li>
      </ul>

      <h2 className='text-xl font-medium mt-6 mb-1'>6. User Responsibility</h2>
      <ul>
        <li>
          You are responsible for your own mental health decisions and actions
          taken based on interactions with our AI.
        </li>
        <li>
          We encourage you to critically evaluate the AI's responses and use
          your judgment when considering its advice.
        </li>
      </ul>

      <h2 className='text-xl font-medium mt-6 mb-1'>7. Potential Risks</h2>
      <ul>
        <li>
          There is a risk of misunderstanding or misinterpreting the AI's
          responses.
        </li>
        <li>
          Overreliance on AI therapy may delay seeking necessary professional
          help.
        </li>
        <li>
          The AI may not be able to identify or respond appropriately to signs
          of serious mental health conditions.
        </li>
      </ul>

      <h2 className='text-xl font-medium mt-6 mb-1'>
        8. Continuous Development
      </h2>
      <ul>
        <li>Our AI system is continually evolving and improving.</li>
        <li>
          The capabilities and limitations of the AI may change over time, and
          we will update this disclaimer accordingly.
        </li>
      </ul>

      <h2 className='text-xl font-medium mt-6 mb-1'>
        9. Feedback and Reporting
      </h2>
      <ul>
        <li>
          We encourage users to provide feedback on their experience with the
          AI.
        </li>
        <li>
          If you encounter any concerning or inappropriate responses from the
          AI, please report them to our support team immediately.
        </li>
      </ul>

      <p>
        By using calmi, you acknowledge that you have read, understood, and
        agree to this AI disclaimer. If you do not agree with any part of this
        disclaimer, please do not use our services.
      </p>

      <p className='mt-6'>
        For any questions or concerns about this AI disclaimer, please contact
        us at: <br />
        Dennis Aydin <br />
        <a href='mailto:dennis@calmi.so'>dennis@calmi.so</a>
      </p>
    </div>
  );
};
