export const SERVER_WS_URL =
  process.env.REACT_APP_SERVER_WS_URL ?? 'ws://localhost:8001';

export const START_LISTENING_TOKEN = 'RDY'; // Sent by server to indicate start VAD
export const END_OF_SPEECH_TOKEN = 'EOS'; // End of speech on client side
export const INTERRUPT_TOKEN = 'INT'; // Interrupt reported from client side
export const CLEAR_BUFFER_TOKEN = 'CLR'; // Clear playback buffer request from server

// These are shared between streamer and playback but
// we are using float32arrays of pcm 24k 16bit mono
export const AUDIO_CONTEXT_SETTINGS = {
  sampleRate: 24000,
  bitDepth: 16,
  numChannels: 1,
  echoCancellation: true,
  autoGainControl: true,
  noiseSuppression: true,
  channelCount: 1,
};

export const MAX_PREV_MESSAGES_LIMIT = 8;
