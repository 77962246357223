import { useCallback, useEffect, useState } from 'react';
import { Logs } from '../components/logs';
import { MAX_PREV_MESSAGES_LIMIT } from '../constants';
import { supabase } from '../supabaseClient';
import { isMobile } from '../utils';

export const useLogs = () => {
  const [logs, setLogs] = useState<{ message: string }[]>([]);
  const [loading, setLoading] = useState(false);

  const isMobileDevice = isMobile();

  const setLogsFromMessageHistory = useCallback(async () => {
    setLoading(true);

    const { data } = await supabase
      .from('message_history')
      .select()
      .order('created_at', { ascending: false })
      .limit(MAX_PREV_MESSAGES_LIMIT);

    if (!data || data.length === 0) {
      setLogs([
        {
          message: `no previous messages. ${
            isMobileDevice ? '<tap>' : '<click>'
          } circle to start conversation ↑`,
        },
      ]);
      setLoading(false);
      return;
    }

    const processedLogs = data
      .reverse()
      .map((message) => {
        const role = message.role === 'user' ? 'user' : 'calmi';
        let processedMessage = `${role}: ${message.message}`.toLowerCase();
        processedMessage = processedMessage.replace(/\bassistant\b/gi, 'calmi');

        if (processedMessage.includes('user:')) {
          processedMessage = `user: ${processedMessage
            .replace('user:', '')
            .trim()}`;
        } else {
          processedMessage = processedMessage
            .replace(/^(assistant:|calmi:)\s*/i, '')
            .trim();
        }

        return { message: processedMessage };
      })
      .filter(
        (log) =>
          !log.message.includes('listening...') &&
          !log.message.includes('processing...')
      );

    setLogs(processedLogs);
    setLoading(false);
  }, []);

  useEffect(() => {
    setLogsFromMessageHistory();
  }, [setLogsFromMessageHistory]);

  const clearLogs = useCallback(() => {
    setLogs([]);
  }, []);

  const logMessage = useCallback((message: string) => {
    setLogs((prevLogs) => {
      let processedMessage = message
        .toLowerCase()
        .replace(/\bassistant\b/gi, 'calmi');

      if (
        processedMessage.includes('listening...') ||
        processedMessage.includes('processing...')
      ) {
        return prevLogs;
      } else if (processedMessage.includes('user:')) {
        processedMessage = `user: ${processedMessage
          .replace('user:', '')
          .trim()}`;
      } else {
        processedMessage = processedMessage
          .replace(/^(assistant:|calmi:)\s*/i, '')
          .trim();
      }

      return [...prevLogs, { message: processedMessage }];
    });
  }, []);

  const logDisplay = useCallback(() => {
    const logLines = logs.map((log, index) => <p key={index}>{log.message}</p>);

    return <Logs logLines={logLines} clearLogs={clearLogs} loading={loading} />;
  }, [logs, clearLogs, loading]);

  return [logMessage, logDisplay, clearLogs] as const;
};
