import React from 'react';

const ToggleSwitch = ({ initialState = false, onToggle }) => {
  return (
    <div className="flex items-center">
      <span className="mr-3 text-sm font-medium font-mono">"gen z" mode</span>
      <div className="relative inline-block w-10 mr-2 align-middle select-none">
        <input
          type="checkbox"
          name="toggle"
          id="toggle"
          className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer"
          checked={initialState}
          onChange={(e) => onToggle(e.target.checked)}
        />
        <label htmlFor="toggle" className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
      </div>
    </div>
  );
};

export default ToggleSwitch;