import { useCallback, useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { UserWithDetails } from '../types';

export const useUserData = () => {
  const [user, setUser] = useState<UserWithDetails | null>(null);
  const [loadingUser, setLoadingUser] = useState(false);

  const getUserData = useCallback(async () => {
    setLoadingUser(true);

    const { data: authUser } = await supabase.auth.getUser();
    const userId = authUser.user?.id;

    if (!userId) {
      setUser(null);
      setLoadingUser(false);
      return;
    }

    const { data: userData } = await supabase
      .from('users')
      .select()
      .eq('user_id', userId)
      .single();

    setUser(userData);
    setLoadingUser(false);
  }, []);

  const refreshUser = useCallback(() => {
    getUserData();
  }, [getUserData]);

  useEffect(() => {
    getUserData();

    const { data: authListener } = supabase.auth.onAuthStateChange((event) => {
      if (event === 'SIGNED_IN' || event === 'SIGNED_OUT') {
        getUserData();
      }
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [getUserData]);

  return { user, loadingUser, refreshUser };
};
