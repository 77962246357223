export const PrivacyPolicyPage = () => {
  return (
    <div className='px-4 sm:px-8 md:px-12 lg:px-24 my-8 sm:my-12 lowercase max-w-[768px] mx-auto'>
      <h1 className='text-2xl font-bold'>calmi Privacy Policy</h1>
      <p>
        <strong>Last Updated:</strong> 09.14.2024
      </p>

      <h2 className='text-xl font-medium mt-6 mb-1'>1. Introduction</h2>
      <p>
        This Privacy Policy explains how calmi (calmi.so) collects, uses,
        stores, and protects your personal information. By using our app, you
        consent to the data practices described in this policy.
      </p>

      <h2 className='text-xl font-medium mt-6'>2. Information We Collect</h2>
      <h3 className='text-base font-medium mt-2 mb-1'>
        2.1 Personal Information
      </h3>
      <ul>
        <li>
          <strong>Email Address</strong>: Required for account registration and
          communication.
        </li>
        <li>
          <strong>Name and Last Name</strong>: May be collected in the future
          for personalization.
        </li>
        <li>
          <strong>Payment Information</strong>: Processed securely through
          Stripe for subscriptions.
        </li>
      </ul>

      <h3 className='text-base font-medium mt-4 mb-1'>2.2 Usage Data</h3>
      <ul>
        <li>
          <strong>Conversation Logs</strong>: Content of your interactions with
          our AI therapist.
        </li>
        <li>
          <strong>Usage Analytics</strong>: Information about how you use the
          app, including session duration and frequency.
        </li>
      </ul>

      <h3 className='text-base font-medium mt-4 mb-1'>2.3 Technical Data</h3>
      <ul>
        <li>
          <strong>Device Information</strong>: Type of device, operating system,
          and browser used.
        </li>
        <li>
          <strong>IP Address</strong>: Used for security and localization
          purposes.
        </li>
      </ul>

      <h2 className='text-xl font-medium mt-6 mb-1'>
        3. How We Use Your Information
      </h2>
      <p>We use your information to:</p>
      <ul>
        <li>Create and manage your account</li>
        <li>Provide and improve our AI therapy services</li>
        <li>Send product updates, marketing materials, and solicit feedback</li>
        <li>Process payments and manage subscriptions</li>
        <li>Analyze app usage to improve our services</li>
        <li>Comply with legal obligations</li>
      </ul>

      <h2 className='text-xl font-medium mt-6 mb-1'>
        4. Data Storage and Security
      </h2>
      <ul>
        <li>All personal information is stored securely in Supabase.</li>
        <li>We host our app on Digital Ocean.</li>
        <li>
          We implement industry-standard security measures to protect your data.
        </li>
        <li>Conversation logs are encrypted end-to-end.</li>
      </ul>

      <h2 className='text-xl font-medium mt-6 mb-1'>5. Third-Party Services</h2>
      <p>We use the following third-party services:</p>
      <ul>
        <li>
          <strong>Stripe</strong>: Payment processing
        </li>
        <li>
          <strong>Digital Ocean</strong>: App hosting
        </li>
        <li>
          <strong>Resend</strong>: Email services
        </li>
        <li>
          <strong>Supabase</strong>: Database management
        </li>
      </ul>
      <p>
        These services have their own privacy policies governing their use of
        your information.
      </p>

      <h2 className='text-xl font-medium mt-6 mb-1'>
        6. Data Sharing and Disclosure
      </h2>
      <p>
        We do not sell, trade, or rent your personal information to third
        parties. We may share your information:
      </p>
      <ul>
        <li>With third-party service providers as mentioned above</li>
        <li>If required by law or to protect our rights</li>
        <li>
          In the event of a merger, acquisition, or sale of all or a portion of
          our assets
        </li>
      </ul>

      <h2 className='text-xl font-medium mt-6 mb-1'>7. User Rights</h2>
      <p>You have the right to:</p>
      <ul>
        <li>Access and update your personal information</li>
        <li>Request deletion of your account and associated data</li>
        <li>Opt-out of marketing communications</li>
        <li>Object to the processing of your personal data</li>
        <li>Request a copy of your data in a portable format</li>
      </ul>
      <p>
        To exercise these rights, please contact us at{' '}
        <a href='mailto:dennis@calmi.so'>dennis@calmi.so</a>.
      </p>

      <h2 className='text-xl font-medium mt-6 mb-1'>8. Data Retention</h2>
      <p>
        We retain your personal information for as long as your account is
        active or as needed to provide you services. We will delete your data
        upon request, except where we are required to retain it for legal
        purposes.
      </p>

      <h2 className='text-xl font-medium mt-6 mb-1'>
        9. Cookies and Tracking Technologies
      </h2>
      <p>
        We use cookies and similar tracking technologies to enhance your
        experience on our app. You can control cookie settings through your
        browser preferences.
      </p>

      <h2 className='text-xl font-medium mt-6 mb-1'>
        10. International Data Transfers
      </h2>
      <p>
        Your information may be transferred to and processed in countries other
        than your own. We ensure appropriate safeguards are in place to protect
        your information in compliance with applicable data protection laws.
      </p>

      <h2 className='text-xl font-medium mt-6 mb-1'>11. Children's Privacy</h2>
      <p>
        Our service is not directed to individuals under 13. We do not knowingly
        collect personal information from children. If we become aware of such
        data being collected, we will delete it immediately.
      </p>

      <h2 className='text-xl font-medium mt-6 mb-1'>
        12. Changes to This Policy
      </h2>
      <p>
        We may update this Privacy Policy periodically. We will notify you of
        any significant changes via email or through the app.
      </p>

      <h2 className='text-xl font-medium mt-6 mb-1'>13. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at <a href='mailto:dennis@calmi.so'>dennis@calmi.so</a>.
      </p>
    </div>
  );
};
