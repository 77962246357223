import { Check, Sparkle, Sparkles } from 'lucide-react';

import { User } from '@supabase/supabase-js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../components';
import { PLAN } from '../constants';
import { supabase } from '../supabaseClient';

export const PricingPage = () => {
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();

  const setUserFromDb = async () => {
    const user = await supabase.auth.getUser();

    setUser(user.data.user);
  };

  useEffect(() => {
    setUserFromDb();
  }, []);

  return (
    <div className='container mx-auto px-4 py-8'>
      <h1 className='text-3xl font-medium text-center mb-8 font-sans'>
        upgrade your plan
      </h1>
      <div className='mx-auto grid gap-4 max-w-[500px]  sm:gap-0 sm:grid-cols-2 sm:max-w-[600px]'>
        <Card className='sm:border-r-0 sm:rounded-r-none sm:h-96'>
          <CardHeader>
            <CardTitle className='flex items-center gap-2'>
              <Sparkle fill='black' width={16} /> free
            </CardTitle>
            <CardDescription className='font-mono'>
              USD $0/month
            </CardDescription>
          </CardHeader>
          <CardFooter className='flex flex-col items-stretch gap-4 font-mono'>
            <Button
              variant='secondary'
              className='w-full rounded-full'
              disabled
            >
              current plan
            </Button>
          </CardFooter>
          <CardContent className='grid gap-1'>
            <div className='flex items-center'>
              <Check className='mr-2 h-3 w-3 text-black' />
              <span className='text-sm font-medium'>3 sessions free</span>
            </div>
            <div className='flex items-center'>
              <Check className='mr-2 h-3 w-3 text-black' />
              <span className='text-sm font-medium'>literally no money</span>
            </div>
            <div className='flex items-center'>
              <Check className='mr-2 h-3 w-3 text-black' />
              <span className='text-sm font-medium'>
                did we mention it's free?
              </span>
            </div>
          </CardContent>
        </Card>

        <Card className='sm:rounded-l-none sm:h-96'>
          <CardHeader>
            <CardTitle className='flex items-center gap-2'>
              <Sparkles fill='black' width={16} /> plus
            </CardTitle>
            <CardDescription className='font-mono'>
              USD $14/month
            </CardDescription>
          </CardHeader>
          <CardFooter>
            <Button className='w-full rounded-full bg-[#FFC700] hover:bg-[#FFB900] text-[#1E1E1E] font-bold font-mono'>
              <a
                rel='noreferrer'
                target='_blank'
                href={PLAN.link + '?prefilled_email=' + user?.email}
                onClick={() => navigate('/')}
              >
                upgrade plan
              </a>
            </Button>
          </CardFooter>
          <CardContent className='grid gap-1'>
            <div className='flex items-center'>
              <Check className='mr-2 h-3 w-3 text-black' />
              <span className='text-sm font-medium'>
                all the <span className='font-bold'>free</span> stuff
              </span>
            </div>
            <div className='flex items-center'>
              <Check className='mr-2 h-3 w-3 text-black' />
              <span className='text-sm font-medium'>
                <span className='font-bold'>unlimited</span> therapy sessions
              </span>
            </div>
            <div className='flex items-center'>
              <Check className='mr-2 h-3 w-3 text-black' />
              <span className='text-sm font-medium'>help improve the AI</span>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
