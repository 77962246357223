import EventEmitter from 'events';
import { AUDIO_CONTEXT_SETTINGS, END_OF_SPEECH_TOKEN } from '../constants';

export class Streamer extends EventEmitter {
  ws: WebSocket;
  stream: MediaStream | null = null;
  processor: ScriptProcessorNode | null = null;
  audioContext: AudioContext | null = null;
  userIsSpeaking: boolean = false;
  currentLogMessage: string | null = null;

  constructor(ws: WebSocket, private logMessage: (...args: any[]) => void) {
    super();
    this.ws = ws;
    this.audioContext = new AudioContext(AUDIO_CONTEXT_SETTINGS);
  }

  async start() {
    const constraints = { audio: true };
    this.stream = await navigator.mediaDevices.getUserMedia(constraints);
    const source = this.audioContext!.createMediaStreamSource(this.stream);
    this.processor = this.audioContext!.createScriptProcessor(1024, 1, 1);
    this.processor.onaudioprocess = (event) => {
      if (this.ws.readyState === WebSocket.OPEN && this.userIsSpeaking) {
        this.ws.send(event.inputBuffer.getChannelData(0));
      }
    };
    source.connect(this.processor);
    this.processor.connect(this.audioContext!.destination);
  }

  startSpeech() {
    this.userIsSpeaking = true;
    this.emit('speechStart');
    this.logMessage('listening...');
    this.currentLogMessage = 'listening...';
  }

  endSpeech() {
    this.userIsSpeaking = false;
    this.emit('speechEnd');
    this.logMessage('processing...');
    this.currentLogMessage = 'processing...';
    this.ws.send(END_OF_SPEECH_TOKEN);
  }

  async stop(graceful: boolean = false) {
    this.audioContext?.suspend();
    this.stream?.getTracks().forEach((track) => {
      track.stop();
      this.stream?.removeTrack(track);
    });
    this.processor && (this.processor.onaudioprocess = null);
  }
}

export class Playback extends EventEmitter {
  samples: Float32Array[] = [];
  lastFramePlayed: 'silence' | 'non-silence' = 'silence';
  analyser: AnalyserNode | null = null;

  constructor(public audioContext: AudioContext) {
    super();
    this.audioContext.suspend();
    const scriptNode = this.audioContext.createScriptProcessor(1024, 1, 1);

    // Create an analyser node
    this.analyser = this.audioContext.createAnalyser();
    this.analyser.fftSize = 256;

    scriptNode.onaudioprocess = (event) => {
      if (this.samples.length > 0) {
        if (this.lastFramePlayed === 'silence') {
          this.emit('playbackStart');
        }
        this.lastFramePlayed = 'non-silence';
        event.outputBuffer.getChannelData(0).set(this.samples[0]);
        this.samples.shift();
      } else {
        if (this.lastFramePlayed === 'non-silence') {
          this.emit('playbackEnd');
        }
        this.lastFramePlayed = 'silence';
        const silence = new Float32Array(1024);
        event.outputBuffer.getChannelData(0).set(silence);
      }
    };

    const gainNode = this.audioContext.createGain();
    gainNode.gain.value = 0.5;
    scriptNode.connect(this.analyser);
    this.analyser.connect(gainNode);
    gainNode.connect(this.audioContext.destination);
  }

  // ... (rest of the Playback class methods)
  async clear() {
    await this.audioContext.suspend();
    const dirty = this.samples.length > 0;
    this.samples = [];
    await this.audioContext.resume();
    this.emit('clear', { dirty });
    this.lastFramePlayed = 'silence';
    return dirty;
  }

  start() {
    this.audioContext.resume();
  }

  stop(graceful: boolean = false) {
    if (graceful) {
      if (this.samples.length > 0) {
        return setTimeout(() => {
          this.stop(true);
        }, 1000);
      }
    } else {
      this.audioContext.suspend();
    }
  }

  addSamples(samples: Float32Array) {
    this.samples.push(samples);
  }
}
