import { Loader2 } from 'lucide-react';
import { useEffect, useRef } from 'react';
import { Skeleton } from './ui/skeleton';

export const Logs = ({
  logLines,
  clearLogs,
  loading,
}: {
  logLines: JSX.Element[];
  clearLogs: () => void;
  loading: boolean;
}) => {
  const logContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (logContainerRef.current) {
      logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
    }
  }, [logLines]);

  return (
    <div className='w-full lg:w-[500px] xl:w-[700px] max-w-3xl mx-auto'>
      <div className='flex justify-between items-center mb-1'>
        <h3 className='text-sm font-bold'>transcript</h3>
        {/* <button
          onClick={clearLogs}
          className='font-mono px-2 py-1 rounded-md text-[#1E1E1E] font-bold text-xs transition duration-300 ease-in-out transform hover:scale-105 bg-[#FFC700] hover:bg-[#FFB900]'
        >
          clear
        </button> */}
      </div>
      <div
        ref={logContainerRef}
        className='relative bg-white h-[350px] grid gap-2 content-start overflow-y-auto text-xs rounded border border-gray-400 p-2'
      >
        {loading ? (
          <>
            <div className='grid gap-2 content-start'>
              <Skeleton className='w-48 h-8 rounded-md justify-self-end' />
              <Skeleton className='w-56 h-12 rounded-md' />
              <Skeleton className='w-60 h-8 rounded-md justify-self-end' />
              <Skeleton className='w-64 h-16 rounded-md' />
              <Skeleton className='w-80 h-20 rounded-md justify-self-end' />
              <Skeleton className='w-80 h-8 rounded-md' />
            </div>

            <div className='absolute top-36 mx-auto w-full flex flex-col items-center justify-start gap-1'>
              <Loader2 className='h-4 w-4 animate-spin' />
              <p className='text-sm'>loading previous conversations...</p>
            </div>
          </>
        ) : (
          <>
            {logLines.map((line, index) => (
              <div
                key={index}
                className={`max-w-[90%] ${
                  line.props.children.startsWith('user:')
                    ? 'justify-self-end text-right'
                    : 'justify-self-start text-left'
                }`}
              >
                <span
                  className={`inline-block p-2 rounded-lg ${
                    line.props.children.startsWith('user:')
                      ? 'bg-[#E5E5E5] text-black'
                      : 'bg-[#f9f9f9] text-black border-gray-300'
                  }`}
                >
                  {line.props.children.replace(/^(user|calmi):/, '').trim()}
                </span>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
