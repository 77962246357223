import { ThemeSupa, ViewType } from '@supabase/auth-ui-shared';
import { SquareArrowOutUpRight } from 'lucide-react';
import { supabase } from '../supabaseClient';
import Auth from './auth/auth';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';

interface AuthDialogProps {
  buttonText?: string;
  view?: ViewType;
  headerText?: string;
}

const AuthDialog: React.FC<AuthDialogProps> = ({
  buttonText = 'connect now',
  view = 'sign_up',
  headerText = 'sign up',
}) => {
  return (
    <Dialog>
      <DialogTrigger>
        <button className='home-to-session-button font-mono font-bold'>
          {buttonText}
        </button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle hidden={true}>calmi authentication</DialogTitle>
        <DialogHeader>
          <DialogDescription>
            <Auth
              supabaseClient={supabase}
              appearance={{
                theme: ThemeSupa,
                variables: {
                  default: {
                    colors: {
                      brand: '#FFC700',
                      brandAccent: '#e69500',
                    },
                  },
                },
                style: {
                  button: {
                    fontFamily: "'JetBrains Mono', monospace",
                    fontWeight: 'bold',
                    fontSize: '18.72px',
                    borderRadius: '5px',
                    backgroundColor: '#FFC700',
                    color: 'black',
                    textTransform: 'lowercase',
                  },
                  input: {
                    fontFamily: "'JetBrains Mono', monospace",
                    fontSize: '16px',
                    textTransform: 'lowercase',
                  },
                  label: {
                    textAlign: 'left',
                    fontFamily: "'Inter', sans serif",
                    fontSize: '16px',
                    textTransform: 'lowercase',
                  },
                  anchor: {
                    textTransform: 'lowercase',
                  },
                },
              }}
              localization={{
                variables: {
                  sign_in: {
                    button_label: 'log in',
                  },
                  sign_up: {
                    button_label: 'sign up',
                  },
                },
              }}
              providers={[]}
              socialLayout='horizontal'
              view={view}
            />
            {view === 'sign_up' && (
              <p className='text-center text-balance '>
                by signing up, you agree to our{' '}
                <a
                  className='font-medium'
                  href='/terms-of-service'
                  target='_blank'
                >
                  <span className='inline-flex items-center gap-[2px]'>
                    terms of service
                    <SquareArrowOutUpRight width={10} />
                  </span>
                </a>
                <span>,</span>
                <a
                  className='font-medium'
                  href='/privacy-policy'
                  target='_blank'
                >
                  {' '}
                  <span className='inline-flex items-center gap-[2px]'>
                    privacy policy
                    <SquareArrowOutUpRight width={10} />
                  </span>
                </a>{' '}
                and acknowledge that our{' '}
                <a
                  className='font-medium'
                  href='/ai-disclaimer'
                  target='_blank'
                >
                  <span className='inline-flex items-center gap-[2px]'>
                    ai disclaimer
                    <SquareArrowOutUpRight width={10} />
                  </span>
                </a>
              </p>
            )}
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default AuthDialog;
