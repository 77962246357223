import AuthDialog from './auth-dialog';
import { Testimonials } from './testimonials';

export default function home() {
  return (
    <div className='home'>
      <div className='header-nav flex justify-between items-center px-4 sm:px-8 md:px-12 lg:px-16 xl:px-20 max-w-7xl mx-auto py-4 sm:py-6 md:py-8 lg:py-10 bg-white'>
        <h1 className='logo text-2xl sm:text-3xl md:text-4xl font-black font-sans'>
          calmi
        </h1>
        <div className='flex items-center space-x-2 sm:space-x-4'>
          <button className='px-2 py-1 sm:px-3 sm:py-1 text-xs sm:text-sm md:text-base rounded-md text-[#1E1E1E] bg-[#FF9472] hover:bg-[#FF8362] font-bold font-mono'>
            <AuthDialog
              buttonText='log in'
              view='sign_in'
              headerText='log in'
            />
          </button>
          <button className='px-2 py-1 sm:px-3 sm:py-1 text-xs sm:text-sm md:text-base rounded-md text-[#1E1E1E] bg-[#FFC700] hover:bg-[#FFB900] font-bold font-mono'>
            <AuthDialog
              buttonText='sign up'
              view='sign_up'
              headerText='sign up'
            />
          </button>
        </div>
      </div>
      <main className='main-home'>
        <div className='content-wrapper px-4 sm:px-8 md:px-12 lg:px-24 xl:px-40 py-4 sm:py-6 md:py-8 mt-2 sm:mt-3 flex flex-col items-center sm:items-start'>
          <div className='main-text max-w-full sm:max-w-xl md:max-w-2xl mx-4 sm:mx-8 md:mx-12'>
            <h1 className='text-3xl sm:text-4xl md:text-5xl lg:text-6xl mb-3 sm:mb-4 leading-tight text-left sm:text-left'>
              <span className='font-semibold font-sans'>
                talk through your problems with
              </span>{' '}
              <span className='font-bold font-sans'>ease.</span>
            </h1>
            <p className='text-base sm:text-lg md:text-xl mb-4 sm:mb-6 font-medium text-left sm:text-left font-sans'>
              no high fees, no wait times, no downloads, no hassle- just therapy
              with an empathetic AI whenever and wherever you need it.
            </p>
            <div className='flex justify-start sm:justify-start'>
              <button className='px-4 sm:px-5 py-2 rounded-md text-[#1E1E1E] bg-[#FFC700] hover:bg-[#FFB900] font-bold text-base sm:text-lg'>
                <AuthDialog />
              </button>
            </div>
          </div>
        </div>
      </main>

      <div className='blob-container-wrapper px-4 sm:px-8 md:px-12 lg:px-24 my-4 sm:my-7'>
        <div className='blob-container relative overflow-hidden h-[300px] sm:h-[400px] md:h-[500px] lg:h-[600px] w-full max-w-[1100px] mx-auto border-4 sm:border-6 md:border-8 border-[#FFC700] rounded-xl flex flex-col justify-center items-center'>
          <div className='absolute top-2 sm:top-4 md:top-8 left-2 sm:left-4 md:left-8 flex items-center space-x-2 sm:space-x-4'>
            <div className='flex items-center'>
              <span className='mr-1 sm:mr-2 text-xs sm:text-sm font-medium font-mono'>
                "gen z" mode
              </span>
              <div className='relative inline-block w-8 sm:w-10 mr-1 sm:mr-2 align-middle select-none'>
                <input
                  type='checkbox'
                  name='toggle'
                  id='toggle'
                  className='toggle-checkbox absolute block w-4 h-4 sm:w-6 sm:h-6 rounded-full bg-white border-2 sm:border-4 border-gray-300 appearance-none cursor-pointer'
                />
                <label
                  htmlFor='toggle'
                  className='toggle-label block overflow-hidden h-4 sm:h-6 rounded-full bg-gray-300 cursor-pointer'
                ></label>
              </div>
            </div>
          </div>
          <div className='absolute top-2 sm:top-4 md:top-8 right-2 sm:right-4 md:right-8'>
            <button className='px-2 sm:px-3 py-1 rounded-md text-[#1E1E1E] bg-[#FFC700] hover:bg-[#FFB900] font-bold text-xs sm:text-sm font-mono'>
              begin session
            </button>
          </div>
          <div className='flex flex-col items-center justify-center h-full'>
            <div className='w-32 h-32 sm:w-40 sm:h-40 md:w-48 md:h-48 lg:w-60 lg:h-60 bg-[#FFC700] rounded-full animate-breathe mb-6 sm:mb-8 md:mb-12'></div>
          </div>
        </div>
      </div>

      {/* <div className='container mx-auto px-4 py-4'> */}
      {/* <div className="how-it-works-section my-16 sm:my-24 md:my-32">
    <h2 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-12 sm:mb-16 md:mb-24 text-left">the how.</h2>
    <div className="relative w-full max-w-[300px] sm:max-w-[400px] md:max-w-[500px] lg:max-w-[600px] h-[300px] sm:h-[400px] md:h-[500px] lg:h-[600px] mx-auto">
      <div className="absolute inset-0 bg-[#FFC700] rounded-full -z-10 animate-breathe"></div>
      
      <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white p-3 sm:p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow w-48 sm:w-56 md:w-64 lg:w-72 text-left transform hover:scale-105 flex items-center">
        <img src="/images/enter_icon.png" alt="Enter key" className="w-6 h-6 sm:w-8 sm:h-8 mr-2 sm:mr-4 flex-shrink-0" />
        <p className="text-xs sm:text-sm md:text-base font-semibold">press &lt;enter&gt; when you're ready to talk.</p>
      </div>
      <div className="absolute top-1/2 right-0 translate-x-1/4 sm:translate-x-1/3 -translate-y-1/2 bg-white p-3 sm:p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow w-48 sm:w-56 md:w-64 lg:w-72 text-left transform hover:scale-105 flex items-center">
        <img src="/images/stop_icon.png" alt="Stop icon" className="w-6 h-6 sm:w-8 sm:h-8 mr-2 sm:mr-4 flex-shrink-0" />
        <p className="text-xs sm:text-sm md:text-base font-semibold">press &lt;enter&gt; again to stop talking</p>
      </div>
      <div className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/4 sm:translate-y-1/3 bg-white p-3 sm:p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow w-48 sm:w-56 md:w-64 lg:w-72 text-left transform hover:scale-105 flex items-center">
        <img src="/images/speech_icon.png" alt="Speech icon" className="w-6 h-6 sm:w-8 sm:h-8 mr-2 sm:mr-4 flex-shrink-0" />
        <p className="text-xs sm:text-sm md:text-base font-semibold">let <strong>calmi</strong> drop some wisdom.</p>
      </div>
      <div className="absolute top-1/2 left-0 -translate-x-1/4 sm:-translate-x-1/3 -translate-y-1/2 bg-white p-3 sm:p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow w-48 sm:w-56 md:w-64 lg:w-72 text-left transform hover:scale-105 flex items-center">
        <img src="/images/loop_icon.png" alt="loop icon" className="w-6 h-6 sm:w-8 sm:h-8 mr-2 sm:mr-4 flex-shrink-0" />
        <p className="text-xs sm:text-sm md:text-base font-semibold">repeat until you're feeling better.</p>
      </div>
      <div className="absolute inset-0 flex items-center justify-center">
      </div>
    </div>
  </div> */}
      {/* </div> */}

      <div className='grid gap-4 mt-16 px-4 sm:px-8 md:px-12 md:mt-20 lg:px-24 xl:px-40'>
        <h2 className='text-center font-bold text-3xl md:text-4xl'>
          trusted by 1000+ for real support
        </h2>
        <Testimonials />
      </div>

      <div className='cta-section py-16 sm:py-20 text-center'>
        <div className='container mx-auto px-4 sm:px-6 md:px-8'>
          <h2 className='text-3xl md:text-4xl font-bold mb-2 sm:mb-3 font-sans'>
            get started for free
          </h2>
          <p className='text-m sm:text-m mb-6 sm:mb-8 font-mono'>
            be heard. be understood. be better.
          </p>
          <button className='px-4 sm:px-5 py-2 rounded-md text-[#1E1E1E] bg-[#FFC700] hover:bg-[#FFB900] font-bold text-base sm:text-lg transition duration-300 ease-in-out transform hover:scale-105'>
            <AuthDialog buttonText='try calmi free' />
          </button>
        </div>
      </div>
    </div>
  );
}
